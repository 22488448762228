/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState } from 'react';
import { useFormik } from 'formik';
// ICONS
import { FiLock, FiUser } from 'react-icons/fi';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import InputBlock from '../../../components/InputBlock';
import Button from '../../../components/Button';
import FormBlock from '../../../components/FormBlock';
// SERVICES & HELPERS
import * as payTraceService from '../../../services/cms/payTraceService';
import constants from '../../../constants/constants';

const PayTraceAuthForm = (props) => {
  const { setAuthResponse } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        // // Verifies card info, holds amount, allows future Capture for settlement.
        // const APIEndpoint = 'https://api.paytrace.com';
        // const SandboxAPIEndpoint = 'https://api.sandbox.paytrace.com';
        // const keyedAuthorization = '/v1/transactions/authorization/keyed';

        // const SandboxCustomerPaymentData = {
        //   amount: 10,
        //   credit_card: {
        //     number: '4012000098765439',
        //     expiration_month: '12',
        //     expiration_year: '2035'
        //   },
        //   integrator_id: constants.REACT_API_INTEGRATOR_ID,
        //   csc: 999,
        //   billing_address: {
        //     name: 'Mr. Sandbox Customer',
        //     street_address: '123 st',
        //     city: 'Hammond',
        //     state: 'la',
        //     zip: '70401',
        //   }
        // };

        // const payByKeyedAuthorization = `${SandboxAPIEndpoint}${keyedAuthorization}`;

        // const getAllCustomers = `${SandboxAPIEndpoint}/v1/customer/export`;

        // console.log(payByKeyedAuthorization);
        // console.log(SandboxCustomerPaymentData);

        // // const response = await fetch(payByKeyedAuthorization, {
        // //   method: 'POST',
        // //   headers: { 'Content-Type': 'application/json' },
        // //   body: JSON.stringify(SandboxCustomerPaymentData),
        // // });

        // const response = await fetch(getAllCustomers, {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({ integrator_id: constants.REACT_API_INTEGRATOR_ID }),
        // });

        // console.log('response', response);

        // const data = await response.json();

        // console.log('data', data);

        // if (data.success) {
        //   // Payment successful
        //   console.log('Payment successful:', data);
        //   // Perform any necessary actions after successful payment
        // } else {
        //   // Payment declined or unsuccessful
        //   console.error('Payment declined:', data);
        //   // Handle the declined payment scenario
        // }

        const res = await payTraceService.authenticateUser(values.username, values.password);
        if (res) {
          setAuthResponse(res);
          // console.log('AUTH RESPONSE', res);
        }
      } catch (error) {
        // console.error('Error', error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <FormBlock onSubmit={formik.handleSubmit}>
        {/* USERNAME */}
        <InputBlock
          id="username"
          label="Username"
          placeholder="Enter username"
          type="text"
          name="username"
          onChange={formik.handleChange}
          value={formik.values.username}
          iconLeft={<FiUser size="22" className="dark-grey--clr" />}
        />

        {/* PASSWORD */}
        <InputBlock
          id="password"
          label="Password"
          placeholder="Enter password"
          type={passwordVisible ? 'text' : 'password'}
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          iconLeft={<FiLock size="22" className="dark-grey--clr" />}
          inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
          inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
        />
      </FormBlock>

      {/* AUTH FORM ACTIONS */}
      <CustomBlock className="button-container--flex-end">
        <Button
          type="submit"
          text="Sign In"
          className="primary--bg"
          isLoading={formik.isSubmitting}
          isDisabled={formik.isSubmitting || !formik.isValid}
          onClick={formik.handleSubmit}
        />
      </CustomBlock>
    </>
  );
};

export default PayTraceAuthForm;